import { Button, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useState } from "react";
import useApiRequest from "../../lib/hooks/useRequest";
import { DialogContent, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";

interface Product {
  description: string[];
  price: number;
  displayName: "Starter" | "Plus" | "Premium";
  planType: "BASIC" | "STANDARD" | "PROFESSIONAL";
  planDuration: "oneYear" | "twoYears";
  planPaymentPeriod: "annually" | "monthly";
  isRecommended: boolean;
}

const products: Product[] = [
  // monthly - 1 year
  {
    description: ["1 Standort", "max. 5 Stellenangebote", "keine Email-Automatisierung", "1 Nutzer"],
    price: 108.9,
    displayName: "Starter",
    planType: "BASIC",
    planDuration: "oneYear",
    planPaymentPeriod: "monthly",
    isRecommended: false,
  },
  {
    description: ["2 Standorte", "max. 10 Stellenangebote", "Email-Automatisierung", "2 Nutzer"],
    price: 251.9,
    displayName: "Plus",
    planType: "STANDARD",
    planDuration: "oneYear",
    isRecommended: true,
    planPaymentPeriod: "monthly",
  },
  {
    description: [
      "Standard Paket",
      "Unbegrenzte Stellenangebote",
      "Unbegrenzte Nutzer",
      "Rollen- und Rechtemanagement (Standortbezogen)",
    ],
    price: 328.9,
    displayName: "Premium",
    planType: "PROFESSIONAL",
    planDuration: "oneYear",
    isRecommended: false,
    planPaymentPeriod: "monthly",
  },
  // monthly - 2 years
  {
    description: ["1 Standort", "max. 5 Stellenangebote", "keine Email-Automatisierung", "1 Nutzer"],
    price: 99,
    displayName: "Starter",
    planType: "BASIC",
    isRecommended: false,
    planDuration: "twoYears",
    planPaymentPeriod: "monthly",
  },
  {
    description: ["2 Standorte", "max. 10 Stellenangebote", "Email-Automatisierung", "2 Nutzer"],
    isRecommended: true,
    price: 229,
    displayName: "Plus",
    planType: "STANDARD",
    planDuration: "twoYears",
    planPaymentPeriod: "monthly",
  },
  {
    description: [
      "Standard Paket",
      "Unbegrenzte Stellenangebote",
      "Unbegrenzte Nutzer",
      "Rollen- und Rechtemanagement (Standortbezogen)",
    ],
    price: 299,
    displayName: "Premium",
    planType: "PROFESSIONAL",
    planDuration: "twoYears",
    isRecommended: false,
    planPaymentPeriod: "monthly",
  },
  // annually - 1 year
  {
    description: ["1 Standort", "max. 5 Stellenangebote", "keine Email-Automatisierung", "1 Nutzer"],
    isRecommended: false,
    price: 1176.12,
    displayName: "Starter",
    planType: "BASIC",
    planDuration: "oneYear",
    planPaymentPeriod: "annually",
  },
  {
    description: ["2 Standorte", "max. 10 Stellenangebote", "Email-Automatisierung", "2 Nutzer"],
    price: 2720.52,
    isRecommended: true,
    displayName: "Plus",
    planType: "STANDARD",
    planDuration: "oneYear",
    planPaymentPeriod: "annually",
  },
  {
    description: [
      "Standard Paket",
      "Unbegrenzte Stellenangebote",
      "Unbegrenzte Nutzer",
      "Rollen- und Rechtemanagement (Standortbezogen)",
    ],
    price: 3552.12,
    displayName: "Premium",
    isRecommended: false,
    planType: "PROFESSIONAL",
    planDuration: "oneYear",
    planPaymentPeriod: "annually",
  },
  // annually - 2 years
  {
    description: ["1 Standort", "max. 5 Stellenangebote", "keine Email-Automatisierung", "1 Nutzer"],
    price: 1069.2,
    displayName: "Starter",
    isRecommended: false,
    planType: "BASIC",
    planDuration: "twoYears",
    planPaymentPeriod: "annually",
  },
  {
    description: ["2 Standorte", "max. 10 Stellenangebote", "Email-Automatisierung", "2 Nutzer"],
    price: 2473.2,
    displayName: "Plus",
    isRecommended: true,
    planType: "STANDARD",
    planDuration: "twoYears",
    planPaymentPeriod: "annually",
  },
  {
    description: [
      "Standard Paket",
      "Unbegrenzte Stellenangebote",
      "Unbegrenzte Nutzer",
      "Rollen- und Rechtemanagement (Standortbezogen)",
    ],
    price: 3229.2,
    isRecommended: false,
    displayName: "Premium",
    planType: "PROFESSIONAL",
    planDuration: "twoYears",
    planPaymentPeriod: "annually",
  },
];

const SubscriptionModal = ({ onClose }: { onClose: (close?: boolean) => void }) => {
  const [mode, setMode] = useState<"oneYear" | "twoYears">("twoYears");

  return (
    <DialogContent
      className="min-w-[80vw]"
      isClosable={false}
      onInteractOutside={(e) => {
        e.preventDefault();
        return;
      }}
    >
      <DialogHeader>
        <DialogTitle className="text-center">Sie müssen noch ein Paket auswählen um zu starten</DialogTitle>
      </DialogHeader>
      <div className="flex gap-2">
        <div className="h-full">
          <Text className="text-center">Abonnement Laufzeit</Text>
          <div className="flex gap-4 justify-center mt-4 flex-col">
            <Button
              onClick={() => setMode("oneYear")}
              className={`w-full`}
              {...(mode === "oneYear" ? { bg: "blue.200" } : undefined)}
              _hover={{ bg: "blue.200" }}
            >
              12 Monate
            </Button>
            <Button
              onClick={() => setMode("twoYears")}
              className={`w-full`}
              {...(mode === "twoYears" ? { bg: "blue.200" } : undefined)}
              _hover={{ bg: "blue.200" }}
            >
              24 Monate
            </Button>
          </div>
        </div>
        {mode && (
          <>
            {mode === "oneYear" && (
              <div>
                <SubscriptionSelect products={products.filter((product) => product.planDuration === "oneYear")} />
              </div>
            )}
            {mode === "twoYears" && (
              <div>
                <SubscriptionSelect products={products.filter((product) => product.planDuration === "twoYears")} />
              </div>
            )}
          </>
        )}
      </div>
    </DialogContent>
  );
};

const SubscriptionSelect = ({ products }: { products: Product[] }) => {
  const monthly = products.filter((product) => product.planPaymentPeriod === "monthly");
  const annually = products.filter((product) => product.planPaymentPeriod === "annually");
  return (
    <div>
      <Tabs
        position="relative"
        height="100%"
        flex="1"
        display="flex"
        flexDirection="column"
        isLazy
        variant="soft-rounded"
        width="100%"
      >
        <TabList className="flex w-1/2 m-auto bg-slate-200 rounded-sm">
          <Tab className="w-1/2" style={{ borderRadius: "0.25em" }}>
            Monatlich
          </Tab>
          <Tab className="w-1/2" style={{ borderRadius: "0.25em" }}>
            Jährlich (-10%)
          </Tab>
        </TabList>
        <TabPanels display="flex" flexDirection="column" flex="1">
          <TabPanel height="100%" padding="1.5rem 0" className="flex gap-4 justify-center">
            {monthly.map((product) => (
              <ProductCmp key={product.price} product={product} />
            ))}
          </TabPanel>
          <TabPanel height="100%" padding="1.5rem 0" className="flex gap-4 justify-center">
            {annually.map((product) => (
              <ProductCmp key={product.price} product={product} />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

const ProductCmp = ({ product }: { product: Product }) => {
  const [hovers, setHovers] = useState<boolean>(false);
  const { apiRequest } = useApiRequest();

  const parsePrice = () => {
    const price = product.price.toString();
    const [euro, cents] = price.split(".");
    return `${euro.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${cents ? (cents.length === 1 ? `${cents}0` : cents) : "00"}`;
  };

  const onClick = async () => {
    const res = await apiRequest<{
      paymentLink: string;
    }>(
      `stripe/paymentLink?planType=${product.planType.toLowerCase()}&planDuration=${
        product.planDuration
      }&planPaymentPeriod=${product.planPaymentPeriod}`,
      "GET"
    );

    if (res.data?.paymentLink) {
      window.open(res.data.paymentLink, "_blank");
    }
  };

  return (
    <>
      <div
        className={`p-8 w-1/3 flex flex-col gap-2  border-2 relative rounded-lg border-transparent bg-transparent z-10`}
        onMouseEnter={() => setHovers(true)}
        onMouseLeave={() => setHovers(false)}
      >
        <fieldset
          className={`absolute top-0 left-0 right-0 bottom-0 border-2 border-blue-200 rounded-lg transition-colors duration-200 ${
            hovers ? "bg-slate-200" : "bg-transparent"
          }`}
        >
          <legend className="text-center text-sm text-slate-400 box-content whitespace-nowrap">
            <span
              className={`${product.isRecommended ? "px-2" : "h-full overflow-hidden block max-w-0"} text-xl font-bold`}
            >
              Empfohlen
            </span>
          </legend>
        </fieldset>
        <div className="z-10 bg-transparent pointer-events-none">
          <img src={"/product-pic.png"} alt={product.displayName} className="w-32 h-32" />
          <Text className="text-lg font-semibold">
            Kandidat.io {product.displayName} - {product.planDuration === "oneYear" ? "12" : "24"}
          </Text>
          <Text className="text-slate-400">
            {product.planPaymentPeriod === "monthly" ? (
              <>{product.planDuration === "oneYear" ? "12" : "24"} Monate</>
            ) : (
              <>Einmal im Jahr</>
            )}
          </Text>
          <ul>
            {product.description.map((desc) => (
              <li key={desc} className="flex gap-2">
                <div>-</div>
                <Text>{desc}</Text>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-auto flex flex-col gap-2 z-20">
          <Text className="text-3xl font-bold">{parsePrice()} €</Text>
          <Button className="w-full" colorScheme="blue" onClick={onClick}>
            Testversion starten
          </Button>
        </div>
      </div>
    </>
  );
};

// BASIC - 12
// 1285
// 119
// 1 Standort, max. 5 Stellenangebote, keine Email-Automatisierung, 1 Nutzer

// STANDARD - 12
// 2905
// 269
// 2 Standorte, max. 10 Stellenangebote, Email-Automatisierung, 2 Nutzer

// PROFESSIONAL - 12
// 3985
// 369
// Standard Paket, Unbegrenzte Stellenangebote, Unbegrenzte Nutzer, Rollen- und Rechtemanagement (Standortbezogen)

// BASIC - 24
// 1069
// 99
// 1 Standort, max. 5 Stellenangebote, keine Email-Automatisierung, 1 Nutzer

// STANDARD - 24
// 2689
// 249
// 2 Standorte, max. 10 Stellenangebote, Email-Automatisierung, 2 Nutzer

// PROFESSIONAL - 24
// 3769
// 349
// Standard Paket, Unbegrenzte Stellenangebote, Unbegrenzte Nutzer, Rollen- und Rechtemanagement (Standortbezogen)

export default SubscriptionModal;
