import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import CompanyForm from "./CompanyForm";
import { Dispatch, SetStateAction } from "react";

const CompanySettings = ({
  organization,
  setOrganization,
}: {
  organization: OrganizationResponseDto;
  setOrganization: Dispatch<SetStateAction<OrganizationResponseDto | undefined>>;
}) => {
  return (
    <>
      <CompanyForm organization={organization} setOrganization={setOrganization} />
    </>
  );
};

export default CompanySettings;
