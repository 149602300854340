import CreateEditor from "../../lib/utils/richtext-editor";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { CheckIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import {
  ApplicantMailTemplateWithValuesResponseDto,
  ApplicantMailTemplatesResponseDto,
} from "../../lib/interfaces/applicant";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import { DialogClose, DialogFooter, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/components/ui/select";
import Placeholder from "../email-dispatch/Placeholder";

const MailModal = ({
  applicantId,
  mailTemplates,
}: {
  applicantId: string;
  mailTemplates: ApplicantMailTemplatesResponseDto;
}) => {
  enum Step {
    SELECT_TEMPLATE,
    EDIT_TEMPLATE,
  }
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(""));
  const [step, setStep] = useState<Step>(Step.SELECT_TEMPLATE);
  const [selectedTemplate, setSelectedTemplate] = useState<ApplicantMailTemplateWithValuesResponseDto>();
  const [error, setError] = useState<string | null>(null);
  const debounceTimer = useRef<number | undefined>(undefined);
  const [mailIsValid, setMailIsValid] = useState<boolean>();
  const [mailCheckLoading, setMailCheckLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { apiRequest } = useApiRequest();

  const checkMailValidity = useCallback(
    async (mail: string) => {
      if (mail === "") {
        return;
      }
      setMailCheckLoading(true);
      const response = await apiRequest<{ isAvailable: boolean }>(
        `company-locations/mail-validation-applicant?mailSenderPrefix=${mail}`,
        "GET"
      );
      if (response.data) {
        setMailIsValid(response.data.isAvailable);
      }
      setMailCheckLoading(false);
    },
    [apiRequest]
  );

  const formSchema = z.object({
    subject: z.string().min(3, {
      message: "Bitte geben Sie den Betreff ein",
    }),
    mailTemplate: z.object({
      _id: z.string(),
      title: z.string(),
    }),
    body: z.string(),
    fromName: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein",
    }),
    fromMail: z
      .string()
      .min(3, {
        message: "Bitte geben Sie Ihren Email Prefix ein",
      })
      .refine(
        (value) => {
          if (!value) {
            return true;
          }
          const mailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !mailRegex.test(value);
        },
        { message: "Bitte geben Sie keine Email Adresse an" }
      ),
    replyToAddress: z.string().email("Bitte geben Sie eine E-Mail Adresse ein"),
    replyToName: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein",
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subject: "",
      body: "",
      fromMail: "",
      fromName: "",
      replyToAddress: "",
      replyToName: "",
    },
  });

  useEffect(() => {
    if (mailIsValid === false) {
      form.setError("fromMail", {
        message: "Email-Adresse ist schon vergeben",
      });
    } else {
      form.clearErrors("fromMail");
    }
  }, [form, mailIsValid]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const req: {
      subject: string;
      text: string;
      from?: {
        name: string;
        addressPrefix: string;
      };
      replyTo?: {
        name: string;
        address: string;
      };
    } = {
      subject: values.subject,
      text: htmlContent,
    };

    if (values.fromName && values.fromMail) {
      req.from = {
        name: values.fromName,
        addressPrefix: values.fromMail,
      };
    }

    if (values.replyToName && values.replyToAddress) {
      req.replyTo = {
        name: values.replyToName,
        address: values.replyToAddress,
      };
    }

    const response = await apiRequest<string>(`applicants/${applicantId}/mail/${selectedTemplate?._id}`, "POST", {
      body: req,
      toast: { toastText: "Email wurde erfolgreich gesendet" },
    });

    if (response.error) {
      setError(response.error as string);
    } else {
      setError(null);
      form.reset();
      window.location.reload();
    }
  };

  const replacePlaceholders = useCallback(
    (text?: string, templateHard?: ApplicantMailTemplateWithValuesResponseDto) => {
      if ((selectedTemplate && selectedTemplate.template) || templateHard) {
        const template = selectedTemplate ? selectedTemplate : templateHard;
        if (!template) {
          return "";
        }
        if (text !== undefined) {
          let replaced = text;
          const placeholders = template.placeholders;
          placeholders.forEach((placeholder) => {
            replaced = replaced.replaceAll(`[${placeholder.placeholder}]`, placeholder.value);
          });
          return replaced;
        } else {
          let replacedHtml = template.template;
          const placeholders = template.placeholders;
          placeholders.forEach((placeholder) => {
            replacedHtml = replacedHtml?.replaceAll(`[${placeholder.placeholder}]`, placeholder.value);
          });
          return replacedHtml;
        }
      }
      return text ?? "";
    },
    [selectedTemplate]
  );

  const getTemplate = useCallback(
    async (templateId: string) => {
      const response = await apiRequest<ApplicantMailTemplateWithValuesResponseDto>(
        `applicants/${applicantId}/mails/mail-templates/${templateId}`,
        "GET"
      );
      if (response.data) {
        const templateWithId = { ...response.data, _id: templateId === "none" ? "" : templateId };
        setSelectedTemplate(templateWithId);
        form.setValue("subject", replacePlaceholders(response.data.subject ?? "", templateWithId));
        if (response.data.from) {
          form.setValue("fromName", response.data.from.name);
          form.setValue("fromMail", response.data.from.addressPrefix);
        }
        if (response.data.replyTo) {
          form.setValue("replyToName", response.data.replyTo.name);
          form.setValue("replyToAddress", response.data.replyTo.address);
        }
        checkMailValidity(response.data.from?.addressPrefix ?? "");
      }
      return response.data;
    },
    [apiRequest, applicantId, checkMailValidity, form, replacePlaceholders]
  );

  useEffect(() => {
    if (selectedTemplate) {
      setEditorState(convertHtmlToEditorState(replacePlaceholders()));
      setStep(Step.EDIT_TEMPLATE);
    }
  }, [Step.EDIT_TEMPLATE, replacePlaceholders, selectedTemplate]);

  return (
    <>
      <DialogHeader>
        <DialogTitle>Mail senden</DialogTitle>
      </DialogHeader>
      {mailTemplates && (
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit(onSubmit)(e);
            }}
            className="w-full gap-16 h-full bg-white"
          >
            <div>
              <Flex className="gap-24 h-full w-full grow">
                <Box className="w-full">
                  {step === Step.SELECT_TEMPLATE ? (
                    <>
                      <FormField
                        control={form.control}
                        name="mailTemplate"
                        render={({ field }) => (
                          <FormItem className="flex gap-4 mb-4 w-full">
                            <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Email Vorlage</FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <Select
                                  onValueChange={async (value) => {
                                    const template = await getTemplate(value);
                                    field.onChange({
                                      _id: value,
                                      title: template?.title ?? "",
                                    });
                                  }}
                                  defaultValue={field.value?._id ?? ""}
                                >
                                  <FormControl>
                                    <SelectTrigger>
                                      <SelectValue placeholder="Wählen Sie die Email Vorlage aus" />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    <SelectItem value="none" className="cursor-pointer" key="-1">
                                      Kein Template benutzen
                                    </SelectItem>
                                    <hr />
                                    {mailTemplates.labels.map((label) => (
                                      <SelectGroup key={label._id}>
                                        <hr />
                                        <SelectLabel className="font-bold">{label.value}</SelectLabel>
                                        {label.mailTemplates.map((template) => (
                                          <SelectItem
                                            key={template.value}
                                            value={template._id}
                                            className="cursor-pointer"
                                          >
                                            {template.value}
                                          </SelectItem>
                                        ))}
                                      </SelectGroup>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Text className="font-bold mt-4">{selectedTemplate?.title}</Text>
                      <hr />
                      <FormField
                        control={form.control}
                        name="subject"
                        render={({ field }) => (
                          <FormItem className="flex gap-4 mb-4 w-full">
                            <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                              Betreff<span className="text-primary">*</span>
                            </FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <Input
                                  placeholder="Betreff"
                                  {...field}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    field.onChange(replacePlaceholders(e.target.value));
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                      <div>
                        <h3 className="font-bold mt-4">Von:</h3>
                        <div className="ml-4">
                          <FormField
                            control={form.control}
                            name="fromName"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Name<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Input placeholder="Kandidat.io" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="fromMail"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Email<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Flex className="w-full gap-2 items-center">
                                      <Input
                                        placeholder="no-reply"
                                        {...field}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          if (debounceTimer.current !== undefined) {
                                            clearTimeout(debounceTimer.current);
                                          }

                                          debounceTimer.current = window.setTimeout(() => {
                                            checkMailValidity(e.target.value);
                                          }, 500);
                                        }}
                                      />
                                      <Text className="mr-2">@kandidat.io</Text>
                                      {mailCheckLoading && <Spinner />}
                                      {mailIsValid && !mailCheckLoading && <CheckIcon className="text-green-500" />}
                                      {mailIsValid === false && !mailCheckLoading && (
                                        <CloseIcon className="text-red-500" />
                                      )}
                                    </Flex>
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <h3 className="font-bold mt-4">Antwort an:</h3>
                        <div className="ml-4">
                          <FormField
                            control={form.control}
                            name="replyToName"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Name<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Input placeholder="Kandidat.io" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="replyToAddress"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Email<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Flex className="w-full gap-2 items-center">
                                      <Input placeholder="no-reply" {...field} />
                                    </Flex>
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                      <FormField
                        control={form.control}
                        name="body"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-3 w-full">
                            <FormLabel className="w-full font-bold mt-4">
                              Arbeitgebervorteile / benefits<span className="text-primary">*</span>
                            </FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <div>
                                  <Placeholder
                                    placeholders={
                                      selectedTemplate?.placeholders.map((parent) => parent.placeholder) ?? []
                                    }
                                    onPlaceholderClicked={(placeholder) => {
                                      const contentState = editorState.getCurrentContent();
                                      const selectionState = editorState.getSelection();

                                      const newContentState = Modifier.insertText(
                                        contentState,
                                        selectionState,
                                        selectedTemplate?.placeholders.find((p) => p.placeholder === placeholder)
                                          ?.value ?? `[${placeholder}]`
                                      );

                                      const newEditorState = EditorState.push(
                                        editorState,
                                        newContentState,
                                        "insert-characters"
                                      );
                                      setEditorState(newEditorState);
                                    }}
                                  />
                                  <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                </Box>
              </Flex>
            </div>
            <DialogFooter>
              <div className="flex flex-col gap-2">
                <Flex gap="1em" className="w-fit ml-auto">
                  <Button
                    colorScheme="primary"
                    className={`flex gap-2 p-2 text-white ml-auto mt-4`}
                    hidden={step === Step.SELECT_TEMPLATE}
                    variant="outline"
                    onClick={() => {
                      setStep(Step.SELECT_TEMPLATE);
                      form.reset();
                    }}
                  >
                    <span>Zurück</span>
                  </Button>
                  <DialogClose asChild>
                    <Button
                      colorScheme="primary"
                      className={`flex gap-2 p-2 text-white ml-auto mt-4`}
                      hidden={step === Step.EDIT_TEMPLATE}
                      variant="outline"
                      id="close-btn"
                    >
                      <span>Zurück</span>
                    </Button>
                  </DialogClose>
                  {step === Step.EDIT_TEMPLATE && (
                    <>
                      {isConfirmed ? (
                        <Button
                          colorScheme="primary"
                          color="white"
                          className="flex gap-2 p-2 text-white ml-auto mt-4"
                          variant="solid"
                          onClick={async () => {
                            if (!mailIsValid) {
                              return;
                            }
                            const result = await form.trigger();
                            if (result) {
                              onSubmit(form.getValues());
                            }
                          }}
                        >
                          <span>Senden</span>
                        </Button>
                      ) : (
                        <Button
                          colorScheme="primary"
                          color="white"
                          className="flex gap-2 p-2 text-white ml-auto mt-4"
                          variant="solid"
                          onClick={async () => {
                            setIsConfirmed(true);
                          }}
                        >
                          <span>Alle eingaben korrekt?</span>
                        </Button>
                      )}
                    </>
                  )}
                </Flex>
                {error && <Text color="red">{error}</Text>}
              </div>
            </DialogFooter>
          </form>
        </Form>
      )}
    </>
  );
};
export default MailModal;
