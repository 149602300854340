import {
  ColumnDef,
  // ColumnFiltersState,
  // FilterFn,
  SortingState,
  // VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Archive, Copy, Info, MoreHorizontal } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";

import { JobOfferListResponseDto } from "@/lib/interfaces/job-offer";
import { Paginated } from "@/lib/interfaces/utils";
import { Flex, Spinner, Switch, Text, Button as ChakraButton, FormLabel, FormControl } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { Link, useNavigate } from "react-router-dom";
import useApiRequest from "../../lib/hooks/useRequest";
import { Button } from "../../ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/components/ui/table";
import { useUser } from "../../hooks/use-user";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/components/ui/dialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormDescription, FormField, FormItem } from "../../ui/components/ui/form";

interface DataProps {
  firstData?: Paginated<JobOfferListResponseDto>;
  locationId: string;
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

export const DataTable: React.FC<DataProps> = ({ locationId, globalFilter, setGlobalFilter, firstData, setReload }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [archivedData, setArchivedData] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState({});

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [dialog, setDialog] = useState<{
    isOpen: boolean;
    jobOfferId: string;
    type: "archive" | "status";
    additionalData?: {
      kleinanzeigenStatus: "ACTIVE" | "INACTIVE";
      isActive: boolean;
      kleinanzeigenAllowed: boolean;
    };
  }>();

  const [data, setData] = useState<JobOfferListResponseDto[]>([]);
  const { apiRequest } = useApiRequest();
  const { user } = useUser();

  const columns: ColumnDef<JobOfferListResponseDto>[] = [
    {
      accessorKey: "title",
      header: ({ column }) => {
        return <Text>Stellenbezeichnung</Text>;
      },
      cell: ({ row }) => {
        const job = row.original;
        if (archivedData) {
          return <Text>{row.getValue("title")}</Text>;
        }
        return <Link to={`/stellenangebote/${job._id}`}>{row.getValue("title")}</Link>;
      },
    },
    {
      accessorKey: "applicantCount",
      header: ({ column }) => {
        return <Text>Anzahl Bewerbungen (Gesamt)</Text>;
      },
    },
    {
      accessorKey: "activeSince",
      header: ({ column }) => {
        return <Text>Online seit</Text>;
      },
      cell: ({ row }) => {
        return row.getValue("activeSince") !== null ? new Date(row.getValue("activeSince")).toLocaleDateString() : "-";
      },
    },
    {
      accessorKey: "kleinanzeigenStatus",
      header: ({ column }) => {
        return <Text>Kleinanzeigen</Text>;
      },
      cell: ({ row }) => {
        return row.getValue("kleinanzeigenStatus") === "ACTIVE" ? "Aktiv" : "Inaktiv";
      },
    },
    {
      accessorKey: "isActive",
      header: ({ column }) => {
        return <Text className="min-w-36">Status</Text>;
      },
      cell: ({ row }) => {
        const JobOfferListResponseDto = row.original;
        const isDisabled =
          user?.permissionCompanyLocations !== "FULL" &&
          user?.permissionCompanyLocations !== "WRITE" &&
          !user?.permissionOrganizationAdmin;

        return (
          <div
            className={`font-semibold capitalize gap-2 flex items-center ${
              row.getValue("isActive") ? "text-success" : "text-error"
            } ${isDisabled ? "cursor-not-allowed" : ""}`}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isDisabled) return;
              setDialog({
                isOpen: true,
                jobOfferId: JobOfferListResponseDto._id,
                type: "status",
                additionalData: {
                  isActive: row.getValue("isActive"),
                  kleinanzeigenStatus: row.getValue("kleinanzeigenStatus"),
                  kleinanzeigenAllowed: row.original.kleinanzeigenAllowed,
                },
              });
            }}
          >
            {!archivedData && (
              <Switch
                isChecked={row.getValue("isActive")}
                size="sm"
                sx={{ "span.chakra-switch__track:not([data-checked])": { backgroundColor: "gray.400" } }}
                display="flex"
                alignItems="center"
                isDisabled={isDisabled}
              />
            )}
            {row.getValue("isActive") ? "Gesucht" : "Nicht Gesucht"}
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "Details / Kopieren",
      enableHiding: false,
      cell: ({ row }) => {
        const jobOfferListResponseDto = row.original;
        const copy = async () => {
          await apiRequest(`job-offers/${jobOfferListResponseDto._id}`, "POST");
          window.location.reload();
        };

        return (
          <DropdownMenu>
            <DropdownMenuTrigger
              asChild
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="flex flex-col p-3 gap-2">
              {!archivedData && (
                <DropdownMenuItem className="flex gap-2 cursor-pointer">
                  <Link to={`/stellenangebote/${jobOfferListResponseDto._id}`} className="flex gap-2">
                    <Info className="h-4 w-4 text-primary self-center" />
                    Details
                  </Link>
                </DropdownMenuItem>
              )}
              {!archivedData && (
                <DropdownMenuItem
                  className="flex gap-2 cursor-pointer"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await copy();
                  }}
                >
                  <Copy className="h-4 w-4 text-primary self-center" />
                  Kopieren
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                className="flex gap-2 cursor-pointer"
                disabled={user?.permissionCompanyLocations !== "FULL" && !user?.permissionOrganizationAdmin}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!archivedData) {
                    setDialog({
                      isOpen: true,
                      jobOfferId: jobOfferListResponseDto._id,
                      type: "archive",
                    });
                  } else {
                    await archiveToggle(jobOfferListResponseDto._id);
                  }
                }}
              >
                <Archive className="h-4 w-4 text-primary" />
                {!archivedData ? "Archivieren" : "Wiederherstellen"}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 10 }, globalFilter },
    state: {
      pagination,
      sorting,
      rowSelection,
      globalFilter,
    },
  });

  const archiveToggle = async (id: string) => {
    if (id && data) {
      const res = await apiRequest(`job-offers/${id}/${archivedData ? "unarchive" : "archive"}`, "PATCH");
      if (res.status < 300 && res.status >= 200) {
        setData((jobOffers) => jobOffers.filter((item) => item._id !== id));
        setReload((prev) => !prev);
      }
    }
  };

  const handleStatusToggle = async (id: string, newStatus: boolean, kleinanzeigenStatus: "ACTIVE" | "INACTIVE") => {
    if (id && data) {
      setData((jobOffers) =>
        jobOffers.map((item) =>
          item._id === id
            ? {
                ...item,
                isActive: newStatus,
                activeSince: newStatus ? new Date().toISOString() : null,
                kleinanzeigenStatus: newStatus ? kleinanzeigenStatus : "INACTIVE",
              }
            : item
        )
      );

      await apiRequest(`job-offers/${id}/${newStatus ? "activate" : "deactivate"}`, "PATCH", {
        body: {
          kleinanzeigenActive: kleinanzeigenStatus === "ACTIVE" ? true : false,
        },
      });
    }
  };

  const getJobOffers = useCallback(async () => {
    setIsLoading(true);
    if (firstData && !archivedData && pagination.pageIndex === 0) {
      setData(firstData.docs);
      setPageCount(Math.ceil(firstData.count / pagination.pageSize));
      setIsLoading(false);
      return;
    }
    const response = await apiRequest<Paginated<JobOfferListResponseDto>>(
      `job-offers/companyLocation/${locationId}/${archivedData ? "archive" : ""}?limit=${pagination.pageSize}&page=${
        pagination.pageIndex + 1
      }`,
      "GET"
    );
    if (response.data) {
      setData(response.data.docs);
      setPageCount(Math.ceil(response.data.count / pagination.pageSize));
    }
    setIsLoading(false);
  }, [apiRequest, archivedData, locationId, pagination.pageIndex, pagination.pageSize, firstData]);

  useEffect(() => {
    getJobOffers();
  }, [archivedData, getJobOffers, locationId, pagination.pageIndex, pagination.pageSize]);

  return (
    <div className="w-full">
      {dialog?.jobOfferId && (
        <Dialog
          open={dialog?.isOpen}
          onOpenChange={() => setDialog({ isOpen: false, jobOfferId: "", type: "archive" })}
        >
          {dialog.type === "archive" ? (
            <ArchiveDialogContent handleArchiveClick={archiveToggle} id={dialog.jobOfferId} />
          ) : (
            <StatusDialogContent
              handleStatusClick={handleStatusToggle}
              id={dialog.jobOfferId}
              isActive={dialog.additionalData?.isActive ?? false}
              kleinanzeigenStatus={dialog.additionalData?.kleinanzeigenStatus ?? "INACTIVE"}
              kleinanzeigenAllowed={dialog.additionalData?.kleinanzeigenAllowed ?? false}
            />
          )}
        </Dialog>
      )}
      <div className="mb-2">
        <Switch
          isChecked={archivedData}
          display="flex"
          alignItems="center"
          onChange={() => setArchivedData(!archivedData)}
          sx={{ "span.chakra-switch__track:not([data-checked])": { backgroundColor: "gray.400" } }}
        >
          Archivierte anzeigen
        </Switch>
      </div>
      <div className="rounded-md border overflow-hidden">
        {isLoading ? (
          <Flex className="justify-center w-full h-36 items-center bg-white">
            <Spinner size="xl" color="primary.500" />
          </Flex>
        ) : (
          <>
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row, i) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      className={`hover:bg-slate-100 ${row.original.isActive ? "opacity-100" : "opacity-50"} ${
                        !archivedData && "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (!archivedData) {
                          navigate(`/stellenangebote/${row.original._id}`);
                        }
                      }}
                    >
                      {row.getVisibleCells().map((cell, j) => (
                        <TableCell key={cell.id} className={`${j === 0 ? " font-bold" : ""}`}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      Keine Resultate.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="space-x-2 flex items-center">
          <Text fontSize="medium">
            Seite {pagination.pageIndex + 1} von {pageCount}
          </Text>
          <Button
            variant="outline"
            size="sm"
            className="text-base"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            zurück
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="text-base"
          >
            weiter
          </Button>
        </div>
      </div>
    </div>
  );
};

const StatusDialogContent = ({
  handleStatusClick,
  id,
  isActive,
  kleinanzeigenStatus,
  kleinanzeigenAllowed,
}: {
  handleStatusClick: (id: string, newStatus: boolean, kleinanzeigenStatus: "ACTIVE" | "INACTIVE") => Promise<void>;
  id: string;
  isActive: boolean;
  kleinanzeigenStatus: "ACTIVE" | "INACTIVE";
  kleinanzeigenAllowed: boolean;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const schema = z.object({
    kleinanzeigenStatus: z.boolean(),
  });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      kleinanzeigenStatus: kleinanzeigenStatus === "ACTIVE" ? true : false,
    },
  });

  async function onSubmit(values: z.infer<typeof schema>) {
    await handleStatusClick(id, !isActive, values.kleinanzeigenStatus ? "ACTIVE" : "INACTIVE");
    buttonRef.current?.click();
  }

  return (
    <DialogContent>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle>Stellenanzeige {isActive ? "deaktivieren" : "aktivieren"}?</DialogTitle>
          </DialogHeader>
          {!isActive && kleinanzeigenAllowed ? (
            <div>
              <FormField
                control={form.control}
                name="kleinanzeigenStatus"
                render={({ field }) => (
                  <FormItem className="flex flex-col items-center justify-between mt-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">Kleinanzeigen aktivieren</FormLabel>
                      <FormDescription>
                        Wenn die Stellenanzeige aktiviert ist, wird diese auch auf der Kleinanzeigen-Plattform
                        angezeigt.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onChange={field.onChange}
                        size="sm"
                        sx={{ "span.chakra-switch__track:not([data-checked])": { backgroundColor: "gray.400" } }}
                        display="flex"
                        alignItems="center"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          ) : (
            <></>
          )}
          <DialogFooter className="flex gap-2 mt-4">
            <DialogClose asChild>
              <ChakraButton
                borderColor="error"
                textColor="error"
                className="flex gap-2 p-2"
                justifyContent="flex-start"
                variant="outline"
              >
                Schliessen
              </ChakraButton>
            </DialogClose>
            <ChakraButton
              colorScheme="primary"
              className="flex gap-2 p-2 text-white"
              justifyContent="flex-start"
              variant="outline"
              type="submit"
            >
              {isActive ? "Deaktivieren" : "Aktivieren"}
            </ChakraButton>
            <DialogClose className="hidden" ref={buttonRef}>
              close
            </DialogClose>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};

const ArchiveDialogContent = ({ handleArchiveClick, id }: { handleArchiveClick: (id: string) => void; id: string }) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Stellenanzeige archivieren?</DialogTitle>
        <DialogDescription>
          Wird die Stellenanzeige archiviert, so wird diese automatisch inaktiv geschalten und sie kann nicht mehr für
          neu eingehende Bewerber genutzt werden. Dies kann Auswirkungen auf bestehende Zapier oder Make Integrationen
          haben.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <ChakraButton
            colorScheme="primary"
            className="flex gap-2 p-2 text-white"
            justifyContent="flex-start"
            variant="outline"
          >
            Schliessen
          </ChakraButton>
        </DialogClose>
        <DialogClose asChild>
          <ChakraButton
            borderColor="error"
            textColor="error"
            className="flex gap-2 p-2"
            justifyContent="flex-start"
            variant="outline"
            onClick={() => handleArchiveClick(id)}
          >
            Archivieren
          </ChakraButton>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};
