import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import CreateEditor from "../../lib/utils/richtext-editor";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import Placeholder from "../email-dispatch/Placeholder";
import "./settings-styles.css";

const InternalMail = ({
  organization,
  setOrganization,
}: {
  organization: OrganizationResponseDto;
  setOrganization: Dispatch<SetStateAction<OrganizationResponseDto | undefined>>;
}) => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    internalMailTemplateTitle: z.string(),
    internalMailTemplateDescription: z.string(),
    internalMailTemplateSubject: z.string(),
    internalMailTemplateTemplate: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      internalMailTemplateDescription: organization.internalMailTemplateDescription,
      internalMailTemplateSubject: organization.internalMailTemplateSubject,
      internalMailTemplateTemplate: organization.internalMailTemplateTemplate,
      internalMailTemplateTitle: organization.internalMailTemplateTitle,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const res = await apiRequest<OrganizationResponseDto>(`organizations/`, "PATCH", {
      body: {
        internalMailTemplateSubject: values.internalMailTemplateSubject,
        internalMailTemplateTemplate: htmlContent,
      },
      toast: { toastText: "Daten erfolgreich aktualisiert" },
    });

    if (res.data) {
      setOrganization({
        ...organization,
        ...res.data,
      });
    }
  }

  const defaultContent = organization.internalMailTemplateTemplate; // Your default content as HTML
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(defaultContent));

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-8 h-full bg-white">
        <Flex className="h-full flex-col">
          <Text className="text-lg font-bold">{organization.internalMailTemplateTitle}</Text>
          <Text className="text-gray-400 text-sm">{organization.internalMailTemplateDescription}</Text>
          <Box className="flex flex-col">
            <FormField
              control={form.control}
              name="internalMailTemplateSubject"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full grow">
                  <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                    Betreff <span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Betreff" {...field} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="internalMailTemplateTemplate"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-3 w-full grow">
                  <FormLabel className="w-full font-bold mt-4 text-md">Email Template</FormLabel>
                  <Box className="w-full h-full">
                    <Placeholder
                      placeholders={organization.mailTemplatePlaceholders}
                      onPlaceholderClicked={(placeholder) => {
                        const contentState = editorState.getCurrentContent();
                        const selectionState = editorState.getSelection();

                        const newContentState = Modifier.insertText(contentState, selectionState, `[${placeholder}]`);

                        const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
                        setEditorState(newEditorState);
                      }}
                    />
                    <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                    <FormMessage />
                  </Box>
                  {/* <Text className="text-sm text-gray-500">
                    zB:
                    <ul>
                      <li>- Flexible Arbeitszeiten</li>
                      <li>- Tolles Team</li>
                      <li>- pünktliche Gehaltszahlung</li>
                    </ul>
                  </Text> */}
                </FormItem>
              )}
            />
          </Box>
        </Flex>
        <Flex className="gap-4 justify-end mt-auto">
          <Button
            colorScheme="primary"
            color="white"
            className="flex gap-2 p-2 text-white ml-auto mt-4"
            variant="solid"
            type="submit"
          >
            <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
              <Save />
            </Box>
            <span>Speichern</span>
          </Button>
        </Flex>
      </form>
    </Form>
  );
};

export default InternalMail;
