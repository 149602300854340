import { OrganizationResponseDto, UpdateOrganizationRequestDto } from "@/lib/interfaces/organizations";
import CreateEditor from "../../lib/utils/richtext-editor";
import { Box, Button, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import ImageUpload from "../../lib/utils/image-upload";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";

const CompanyForm = ({
  organization,
  setOrganization,
}: {
  organization: OrganizationResponseDto;
  setOrganization: Dispatch<SetStateAction<OrganizationResponseDto | undefined>>;
}) => {
  const { apiRequest } = useApiRequest();

  // const [isDragging, setIsDragging] = useState(false);
  // const { fileUpload } = useFileUpload();
  // const navigate = useNavigate();

  // define form schema with zod with the required fields - in the future we can add validation here
  const formSchema = z.object({
    name: z.string().min(3, { message: "Bitte geben Sie einen Namen ein" }),
    street: z.string().min(1, { message: "Bitte geben Sie Ihre Adresse ein" }),
    postCode: z.string().min(1, { message: "Bitte geben Sie Ihre Postleitzahl ein" }),
    placeName: z.string().min(1, { message: "Bitte geben Sie Ihre Stadt ein" }),
    ceo: z.string().min(1, { message: "Geben Sie den Namen des Regisseurs ein" }),
    employeeBenefitsTemplate: z.string(),
    ciColorPrimary: z.string(),
  });

  // define the form with react-hook-form and zodResolver using the form schema and setting the default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: organization?.name ?? "",
      street: organization?.street ?? "",
      postCode: organization?.postCode ?? "",
      placeName: organization?.placeName ?? "",
      ceo: organization?.ceo ?? "",
      employeeBenefitsTemplate: organization?.employeeBenefitsTemplate ?? "",
      ciColorPrimary: organization?.ciColorPrimary ?? "#000000",
    },
  });

  // Define your form schema with zod

  type FormValues = z.infer<typeof formSchema>;

  async function onSubmit(values: FormValues) {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const req: UpdateOrganizationRequestDto = {
      name: values.name,
      street: values.street,
      postCode: values.postCode,
      placeName: values.placeName,
      ceo: values.ceo,
      employeeBenefitsTemplate: htmlContent,
      ciColorPrimary: values.ciColorPrimary,
    };

    const res = await apiRequest<OrganizationResponseDto>(`organizations/`, "PATCH", {
      body: req,
      toast: { toastText: "Daten erfolgreich aktualisiert" },
    });

    if (res.data) {
      setOrganization({
        ...organization,
        ...res.data,
      });
    }
  }

  const defaultContent = ""; // Your default content as HTML

  const convertHtmlToEditorState = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(contentState);
  };
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(defaultContent));

  useEffect(() => {
    if (organization.employeeBenefitsTemplate) {
      setEditorState(convertHtmlToEditorState(organization.employeeBenefitsTemplate));
    }
  }, [organization]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex gap-8 h-full bg-white">
        <Box className="flex flex-col gap-16 w-1/2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="flex gap-4 w-full">
                <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                  Logo des Unternehmens <span className="text-primary">*</span>
                </FormLabel>
                <ImageUpload
                  route="organizations/image"
                  baseImg={organization?.imageUrl ?? ""}
                  size="small"
                  imageLabel="Logo"
                />
              </FormItem>
            )}
          />
          <Flex className="gap-16 h-full">
            <Box className="w-full">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                      Name des Unternehmens <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="street"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                      Straße & Hausnummer <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Straße & Hausnummer" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="postCode"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                      PLZ <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="PLZ" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="placeName"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-4 text-md">
                      Ort <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Ort" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ceo"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-52 w-full font-bold mt-1 text-md">
                      Verantwortliche(r) Geschäftsführer <span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input placeholder="Max Mustermann" {...field} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
            </Box>
          </Flex>
        </Box>
        <Box className="w-1/2">
          <Box className="h-full flex flex-col">
            <FormField
              control={form.control}
              name="ciColorPrimary"
              render={({ field }) => (
                <FormItem className="gap-4 mb-5 items-center flex">
                  <FormLabel className="w-full font-bold mt-4 text-md">Akzentfarbe wählen</FormLabel>
                  <Flex className="bg-yellow-100 gap-4 w-full px-3 py-1 rounded items-center">
                    <input type="color" className="w-14 h-8 p-0 rounded-lg bg-transparent cursor-pointer" {...field} />
                    <p>{field.value}</p>
                    <FormMessage />
                  </Flex>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="employeeBenefitsTemplate"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-3 w-full grow">
                  <FormLabel className="w-full font-bold mt-4 text-md">Vorlage für Mitarbeitervorteile</FormLabel>
                  <Box className="w-full h-full">
                    <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <Box className="flex flex-col justify-between">
              <Flex className="gap-4 justify-end mt-auto">
                <Button
                  colorScheme="primary"
                  color="white"
                  className="flex gap-2 p-2 text-white ml-auto mt-4"
                  variant="solid"
                  type="submit"
                >
                  <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                    <Save />
                  </Box>
                  <span>Speichern</span>
                </Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </form>
    </Form>
  );
};

export default CompanyForm;
